import {useActions} from '@common/hooks/useActions'
import {useLocale} from '@common/hooks/useLocale'
import {useTypedSelector} from '@common/hooks/useTypedSelector'
import {INavigationElement} from '@common/layout/layout.constants'
import {ECustomActions} from '@common/types'
import {IModalRequestFormProps} from '@redux/app/types'
import ym from 'react-yandex-metrika'

interface IHeaderActionsProps {
  nav: INavigationElement
  index: number
  isMobileNavActive: boolean
}

export const HeaderAction = ({nav, index, isMobileNavActive}: IHeaderActionsProps) => {
  const {settings: appSettings} = useTypedSelector(state => state.app)
  const locale = useLocale()
  const actions = useActions()

  const actionByType: IModalRequestFormProps =
    nav.customActions === ECustomActions.PRESENTATIONS ? {form: 'download', open: true} : {open: true, form: 'header'}

  const colorStyle = nav.customActions === ECustomActions.PRESENTATIONS ? 'link--black' : 'link--violet'
  const formModalStyle = appSettings?.formModal ? `link--${appSettings?.formModal?.button?.type ?? ''}` : ''

  const translatedBtnText = locale === 'en' ? nav.textEn : nav.text
  const projectBtnText = appSettings?.formModal?.button?.title ?? translatedBtnText
  const buttonText = nav.customActions === ECustomActions.PROJECT ? projectBtnText : translatedBtnText

  const analyticsAction = () => {
    if (nav.customActions === ECustomActions.PRESENTATIONS) return ym('reachGoal', 'preza_pop_up')
    if (nav.customActions === ECustomActions.PROJECT) return ym('reachGoal', 'request_open')
  }

  return (
    <li
      key={nav.page ?? 'custom'}
      className="main-nav__item"
      data-nav-item={nav.data}
      style={{transitionDelay: `${isMobileNavActive ? 0.2 * (index + 1) + 0.1 : 0}s`}}
    >
      <button
        className={['main-nav__link', 'link', colorStyle, formModalStyle].join(' ')}
        onClick={() => {
          actions.setApp({
            modalForm: actionByType
          })
          analyticsAction()
        }}
      >
        {buttonText}
      </button>
    </li>
  )
}
