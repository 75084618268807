import React, {FC, useEffect} from 'react';
import {Form, Input} from 'antd';
import {useTranslation} from '@common/hooks/useTranslation';
import {IModalFormContentProps, ITranslation} from '@common/types';
import {useRequestForm} from '@common/hooks/useRequest';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import ym from 'react-yandex-metrika';
import DownloadTypeFields from '@common/layout/components/Main/components/ModalForm/ModalFormItems/DownloadTypeFields';
import HeaderTypeFields from '@common/layout/components/Main/components/ModalForm/ModalFormItems/HeaderTypeFields';
import SubmitButton from '@common/layout/components/Main/components/ModalForm/ModalFormItems/SubmitButton';
import PrivacyField from '@common/layout/components/Main/components/ModalForm/ModalFormItems/PrivacyField';
import HeaderTypeCustomFields
  from '@common/layout/components/Main/components/ModalForm/ModalFormItems/HeaderTypeCustomFields';


export const ModalFormContent: FC<IModalFormContentProps> = ({type, closeForm}) => {
  const t = useTranslation('forms') as ITranslation['forms']
  const {form, checkFieldLabel, setFocusedInput, sendForm} = useRequestForm(type)
  const {modalForm, settings} = useTypedSelector(state => state.app)
  const {contacts} = useTypedSelector(state => state.contacts)

  useEffect(() => {
    if (modalForm?.status === 'success' && type === 'download') {
      const url = contacts?.file.data?.attributes.url
      if (url) {
        fetch(url, {method: 'get'})
          .then(res => res.blob())
          .then(res => {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', contacts?.file.data?.attributes.name ?? 'mu.pdf')
            const href = URL.createObjectURL(res)
            aElement.href = href
            aElement.setAttribute('target', '_blank')
            aElement.click()
            URL.revokeObjectURL(href)
            ym('reachGoal', 'preza')
          })
      }
    }

    if (modalForm?.status === 'success' && type === 'header') {
      ym('reachGoal', 'request_send')
    }
  }, [modalForm?.status])

  return (
    <Form
      form={form}
      onFocus={(e) => setFocusedInput(e.target.id ?? '')}
      onBlur={() => setFocusedInput('')}
      onFinish={sendForm}
    >
      <div className="form-modal__wrapper">
        {type === 'download' && (
          <DownloadTypeFields t={t} checkFieldLabel={checkFieldLabel} />
        )}

        {/** Если в strapi не указаны поля для формы, то будут отображаться дефолтные */}
        {(type === 'header' && !settings?.formModal) && (
          <HeaderTypeFields t={t} checkFieldLabel={checkFieldLabel} />
        )}

        {/** Поля формы из strapi */}
        {(type === 'header' && !!settings?.formModal?.fields.length) && (
          <HeaderTypeCustomFields t={t} checkFieldLabel={checkFieldLabel} />
        )}

        {modalForm?.status === 'error' && (
          <p className="error-message">{t.errors.message}</p>
        )}

        <SubmitButton t={t} type={type} />
      </div>

      {type === 'header' && (
        <PrivacyField t={t} closeForm={closeForm} />
      )}
    </Form>
  )
}
